import React from "react";
import './Company.css';

const Company = () => {
    return (
        <div className="Comapny pg_inner bg_screen1">
            <section className="sec bg_page1">

                <dl className="mission">
                    <dt>「やってみたい」をカタチに。</dt>
                    <dd>
                        <p>私たち TryTreeは<br />人の可能性を広げ、社会を豊かにする。</p><br />
                        <p>人の人生に役立ち、<br className="pcno" />本質的に社会に貢献する。</p><br />
                        <p>そんな思いからできた会社です。</p>
                    </dd>
                </dl>

                <dl className="message">
                    <dt><strong>代表挨拶</strong><span>「人の可能性を広げ、<br className="pcno" />社会を豊かにする」</span></dt>
                    <dd>
                        <p>昨今の我々を取り巻く環境は不安定な時代を迎え、今までの当たり前が当たり前ではない世の中に突入しました。<br />
                            終身雇用が崩壊し、「働く」こと自体の難易度もあがり、仕事をする上でも、より一層個人の能力が求められています。</p>
                        <p>社会格差も広がり、理想とする生活や自らが求める環境を手に入れる為には、いままで通りの通説ではなかなか実現できない状況です。</p>
                        <p>日本の社会課題も浮き彫りとなり、高齢化社会、労働人口の減少、消える自治体など刻一刻と今までの当たり前が崩れており、課題の深刻さは物凄いスピードで私達の生活に直接的に影響する所まで来ています。</p>
                        <p>私達TryTreeは事業を通して人の可能性を広げ、社会課題を解決し、豊かにしていくことをミッションに掲げています。</p>
                        <p>不安定な時代だからこそ、TryTreeに関わって下さる皆さんの「やってみたい」をカタチにすることで、アイディアが生まれ、選択肢が増え、新たな常識を創り出し、それが次の豊かな社会につながると信じています。</p>
                    </dd>
                   
                    <dd>代表取締役 二ノ宮 銀</dd>
                </dl>

                <h1>会社情報</h1>
                <dl className="about">
                    <dt>会社名</dt>
                    <dd>株式会社TryTree</dd>
                    <dt>設立年月</dt>
                    <dd>2022年10月21日</dd>
                    <dt>代表取締役</dt>
                    <dd>二ノ宮銀</dd>
                    <dt>従業員数</dt>
                    <dd>47名（2024年10月1日現在）</dd>
                    <dt>本社所在地</dt>
                    <dd>新潟県上越市大和5-2-7 エンジョイプラザ2F<br />
                        TEL:025-512-2702
                    </dd>
                    <dt>事業内容</dt>
                    <dd>ITソリューション事業<br />
                        地方創生事業<br />
                        コンサルティング事業
                    </dd>
                    <dt>認定資格</dt>
                    <dd>WOMAN’s VALUE AWARD<br />
                        イクメン企業宣言<br />
                        ハッピー・パートナー企業<br />
                        新宿区ワーク・ライフ・バランス推進企業認定<br />
                        <div className="certification">
                            <div>
                                <img src="/img/womans_value_award_logo.webp" />
                            </div>
                            <div>
                                <img src="/img/ikumen_logo.webp" />
                            </div>
                            <div>
                                <img src="/img/happy_partner_logo.webp" />
                            </div>
                        </div>
                    </dd>
                        
                    <dt>新潟本社</dt>
                    <dd>
                        <p>〒943-0861<br />
                        新潟県上越市大和5-2-7 <br className="pcno" />エンジョイプラザ2F<br />
                        TEL:025-512-2702</p><br/>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3182.9972000124644!2d138.2438934747203!3d37.081373572168275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z44CSOTQzLTA4NjEg5paw5r2f55yM5LiK6LaK5biC5aSn5ZKMNS0yLTcg44Ko44Oz44K444On44Kk44OX44Op44K2MkY!5e0!3m2!1sja!2sjp!4v1702907916436!5m2!1sja!2sjp"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        />
                    </dd>
                    <dt>東京支社</dt>
                    <dd>
                        <p>〒160-0022<br />
                        東京都新宿区新宿2-11-7 <br className="pcno" />第33宮庭ビル 5F<br />
                        TEL:03-4400-3021</p><br/>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.4550267506906!2d139.70475197463676!3d35.69041867258426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188da12352de09%3A0x3fb73adff8635f69!2zQ08tV29yayYuLi7mlrDlrr8!5e0!3m2!1sja!2sjp!4v1702909329304!5m2!1sja!2sjp"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        />
                    </dd>
                </dl>
            </section>
        </div>
    )
}

export default Company;
